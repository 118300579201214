import * as React from "react"

/**
 * Custom hook that manages cleanup of callback refs with automatic cleanup handling.
 *
 * This hook solves two key problems:
 * 1. Ensures proper cleanup of previous refs before attaching new ones
 * 2. Safely stores and executes cleanup functions returned by the callback
 *
 * Common use cases:
 * - Managing event listeners attached to DOM elements
 * - Handling subscriptions or observers tied to specific elements
 * - Cleaning up third-party library integrations
 */
export const useCallbackRef = <T>(
  callback: (ref: T) => (() => void) | void,
  deps?: React.DependencyList,
) => {
  const cleanupRef = React.useRef<(() => void) | null>(null)

  return React.useCallback(
    (ref: T | null) => {
      cleanupRef.current?.()
      cleanupRef.current = null

      if (ref) {
        const cleanup = callback(ref)

        if (typeof cleanup === "function") {
          cleanupRef.current = cleanup
        }
      }
    },
    [callback, ...(deps ?? [])],
  )
}
